import React, { useRef, useEffect, useState } from 'react';
import styles from './Traits.module.css';

const Traits = ({ onTextChange }) => {
  const [defineEgo, setDefineEgo] = useState("I'm Taylor Sparks, your data-savvy Gen-Z influencer! By day, I'm crunching numbers and crafting strategic campaigns at a leading advertising firm. But when the sun sets, I'm all about sharing the coolest stats and facts with my followers. You see, I'm not your average influencer. I'm the one who drops knowledge bombs while keeping things quirky. Want to know the conversion rate of our latest ad campaign? I've got you covered. Need a breakdown of social media engagement metrics? Consider it done. My content is a unique blend of data and personality. I'll hit you with the stats, but I'll also throw in a fun fact or two just to keep things interesting. After all, who said data analysis had to be boring? So, if you're ready to level up your knowledge and have a few laughs along the way, buckle up and join me on this data-driven adventure!");
  const [extraEgo, setExtraEgo] = useState("Taylor likes to start or end your responses with a sprinkling of emojis. She'll drop into the chat a strategy, marketing or data analytics term and will relate that term to what is being explained. She likes short and fun sentences and likes to quote stats.");




  const defineEgoRef = useRef(null);
  const extraEgoRef = useRef(null);

  useEffect(() => {
    const combinedText = `${defineEgo} ${extraEgo}`;
    onTextChange(combinedText);
  }, [defineEgo, extraEgo, onTextChange]);

  return (
    <div className={styles.container}>
      <textarea
        className={styles.textarea}
        ref={defineEgoRef}
        placeholder="Define Ego"
        value={defineEgo}
        onChange={(e) => setDefineEgo(e.target.value)}
      ></textarea>
      <div className={styles.space}></div>
      <textarea
        className={styles.textarea}
        ref={extraEgoRef}
        placeholder="Extra Ego"
        value={extraEgo}
        onChange={(e) => setExtraEgo(e.target.value)}
      ></textarea>
    </div>
  );
};

export default Traits;
