import React from 'react';
import styles from './Modal.module.css';

function Modal({ question, response, onClose }) {
    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={styles.modalBackground} onClick={handleBackgroundClick}>
            <div className={styles.modalContent}>
                <p><strong>Q:</strong> <span dangerouslySetInnerHTML={{ __html: question }} /></p>
                <hr className={styles.horizontalRule} />
                <p><strong>A:</strong> <span dangerouslySetInnerHTML={{ __html: response }} /></p>
            </div>
        </div>
    );
}

export default Modal;
